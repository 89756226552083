<script>
import { computed, h } from 'vue';

const defaultRenderFuncs = {
    strong: (h, inner) => h('strong', inner),
    em: (h, inner) => h('em', inner),
};

/**
 * The `render-translation` component is a super-charged
 * `jix_simple_format` that makes it possible to use [render functions]
 * for parts of the translated string. Example:
 *
 *     // <script setup>
 *     import RenderTranslation from 'JIX/components/RenderTranslation.vue';
 *
 *     const myString = {
 *         translation: __("My <bold:very special> string with <link:formatting>"),
 *         render: {
 *             bold: (h, text) => h('strong', text),
 *             link: (h, text) => h('a', { href: "/" }, text),
 *         }
 *     };
 *
 *     // <template>
 *     <RenderTranslation :message="myString" />
 *
 * This will render to the HTML `<span>My <strong>very special</strong>
 * string with <a href="/">formatting</a></span>`.
 *
 * [render functions]: https://vuejs.org/api/render-function.html
 */
export default {
    props: {
        tag: {
            type: String,
            required: false,
            default: 'span',
        },
        message: {
            type: [String, Object],
            required: true,
        },
    },
    setup(props, { attrs }) {
        const messageConfig = computed(() =>
            typeof props.message === "string"
                ? { translation: props.message }
                : props.message);

        const parts = computed(() => {
            const parts = messageConfig.value.translation.split(/<|>/);
            if (parts.length % 2 !== 1) {
                throw new Error("malformed translation format string");
            }
            return parts;
        });

        return () => {
            const vnodes = [];
            for (let i = 0; i < parts.value.length; i++) {
                if (i % 2 === 0) { // text node
                    vnodes.push(parts.value[i]);
                } else {
                    const match = parts.value[i].match(/(\w+):(.*)/);
                    if (match) {
                        const [_wholeMatch, name, text] = match;
                        const renderFunc = messageConfig.value.render?.[name] ?? defaultRenderFuncs[name];
                        if (typeof renderFunc !== "function") {
                            throw new Error(`render function for translation format part ${name} is missing`);
                        }
                        const vnode = renderFunc(h, text);
                        vnodes.push(vnode);
                    } else {
                        throw new Error("malformed translation format string");
                    }
                }
            }

            return h(props.tag, attrs, vnodes);
        };
    },
};
</script>
