import { createApp, shallowReactive, ref, h } from 'vue';

import DsGeoRadiusField from 'JIX/components/ds/DsGeoRadiusField.vue';

const stashes = window.Stash['widgets/georadius'];

function init(id, stash) {
    const container = document.getElementById(id);
    const radius = Number(container.querySelector('[name=radius]').value);
    const address = container.querySelector('[name=address]').value;

    const instance = ref();
    const props = shallowReactive({
        ref: instance,
        class: { 'd-none': stash.disabled },
        label: stash.label,
        radius,
        radiusName: stash.radius_name,
        address,
        addressName: stash.address_name,
        required: !stash.disabled,
        disabled: stash.disabled,
        addressDisabled: false,
        variant: stash.variant,
        'onUpdate:radius': (v) => props.radius = v,
        'onUpdate:address': (v) => props.address = v,
    });
    createApp(() => h(DsGeoRadiusField, props)).mount(container);

    if (stash.geoarea_select) {
        const geoarea = document.querySelector(stash.geoarea_select);
        if (geoarea) {
            geoarea.addEventListener('radius_change.jix', (event) => {
                const { radius_selected } = event.detail;
                if (props.disabled && radius_selected) {
                    if (!props.address) {
                        props.address = address;
                    }
                    window.setTimeout(() => {
                        instance.value.selectAddress();
                    }, 0);
                }
                props.required = radius_selected;
                props.disabled = !radius_selected;
                props.class = { 'd-none': !radius_selected };
            });
        }
    }
}

stashes.forEach(({ id, stash }) => init(id, stash));
