<script setup>
import { ref } from 'vue';
import { v4 as uuidv4 } from 'uuid';

import DsAddressField from 'JIX/components/ds/DsAddressField.vue';
import DsRangeSlider from 'JIX/components/ds/DsRangeSlider.vue';
import RenderTranslation from 'JIX/components/RenderTranslation.vue';

import i18n from './DsGeoRadiusField.i18n.js';

defineEmits(['update:address', 'update:radius']);

defineProps({
    label: {
        type: String,
        default: null,
    },
    radius: {
        type: Number,
        required: false,
        default: null,
    },
    radiusName: {
        type: String,
        default: "",
    },
    address: {
        type: String,
        default: "",
    },
    addressName: {
        type: String,
        default: "",
    },
    required: {
        type: Boolean,
        default: false,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    addressDisabled: {
        type: Boolean,
        default: null,
    },
    rangeSliderInvisible: {
        type: Boolean,
        default: null,
    },
    colsSlider: {
        type: Number,
        default: 5,
    },
    colsInput: {
        type: Number,
        default: 7,
    },
    variant: {
        type: String,
        default: "light",
        validator: (val) => ['light', 'dark'].includes(val),
    },
});

const radiusFieldId = `geo-radius-${uuidv4()}`;

const distFrom = {
    translation: i18n.distFrom(),
    render: {
        abbr: (h, text) => h('abbr', { title: i18n.kilometer() }, text),
    },
};

const addressComponent = ref();

defineExpose({
    selectAddress() {
        const input = addressComponent.value.$el.querySelector('input');
        if (input) {
            input.select();
        }
    },
});
</script>

<template>
    <div class="row geo-radius-row">
        <div v-if="label && radiusFieldId" class="col-12">
            <label :for="radiusFieldId" class="control-label">
                {{ label }}
            </label>
        </div>
        <div :class="['col-md-'+colsSlider, 'geo-radius-col', 'geo-radius-col-slider', 'mb-3']" v-if="rangeSliderInvisible === null ? true : !rangeSliderInvisible">
            <DsRangeSlider
                :min="1"
                :max="100"
                :required="required"
                :disabled="disabled"
                :model-value="radius !== null ? radius : 50"
                :aria-label="i18n.radius()"
                @update:model-value="$emit('update:radius', $event)"
            />
        </div>
        <div :class="['col-md-'+colsInput, 'geo-radius-col', 'geo-radius-col-input', 'mb-3']">
            <input
                class="form-control"
                type="number"
                min="1"
                max="100"
                :name="radiusName"
                :id="radiusFieldId"
                :required="required"
                :disabled="disabled"
                :value="radius"
                :aria-label="i18n.radius()"
                @input="$emit('update:radius', parseInt($event.target.value, 10))"
            >
            <RenderTranslation tag="span" :message="distFrom" />
            <DsAddressField
                ref="addressComponent"
                :name="addressName"
                :model-value="address"
                :required="required"
                :disabled="addressDisabled === null ? disabled : addressDisabled"
                :placeholder="i18n.enterAddress()"
                :variant="variant"
                @update:model-value="$emit('update:address', $event)"
            />
        </div>
    </div>
</template>
