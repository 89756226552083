<script>
/**
 * A counter that is incremented for each instantiated component instance used
 * to generated a unique id.
 */
let counter = 1;
</script>

<script setup>
import { ref, computed, toRefs } from 'vue';

const props = defineProps({
    min: {
        type: Number,
        required: true,
    },
    max: {
        type: Number,
        required: true,
    },
    modelValue: {
        type: Number,
        required: true,
    },
});

const { min, max, modelValue } = toRefs(props);

const emit = defineEmits(['update:modelValue']);

const componentCounter = ref(counter++);

const inputListeners = computed(() => {
    const handler = (event) => emit('update:modelValue', Number(event.target.value));
    return {
        input: handler,
        change: handler,
    };
});

const diff = computed(() => max.value - min.value);
const percentage = computed(() => ((modelValue.value - min.value) / diff.value) * 100);
</script>

<template>
    <input
        v-bind="$attrs"
        v-on="inputListeners"
        :id="'jix-range-vue--'+componentCounter"
        type="range"
        :value="modelValue"
        :min="min"
        :max="max"
        :style="{ '--jix-slider-pct': `${percentage}%` }"
    >
</template>
