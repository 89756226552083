import { __, __x, __nx, __N } from 'JIX/gettext.js';

export default {
    kilometer: () =>
        __('kilometer'),
    distFrom: () =>
        __('<abbr:km> fra'),
    enterAddress: () =>
        __('Indtast adresse'),
    radius: () =>
        __('Radius'),
};
